import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5d606d88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "AddAgentView"
};
const _hoisted_2 = {
  class: "AddAgentForm"
};
const _hoisted_3 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_4 = {
  style: {
    "margin": "16px"
  }
};
import { ref, toRaw } from "vue";
import Common_Router from "@/utils/Common/Common_Router";
import Apis_Agent from "@/apis/Apis_Agent";
import LangCom from "@/utils/Lang/LangViews";
export default {
  __name: 'AddAgent',
  setup(__props) {
    const LangLib = new LangCom('Agent', 'AddAgent');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    // const GetLangSetup = field => LangLib.GetSetupLang(field)

    const PostData = ref({
      username: null,
      password: null,
      name: null,
      contact: null
    });
    const onSubmit = () => {
      let obj = toRaw(PostData.value);
      Apis_Agent.addAgent(obj.username, obj.password, obj.name, obj.contact);
    };
    return (_ctx, _cache) => {
      const _component_HeaderNavBar = _resolveComponent("HeaderNavBar");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_HeaderNavBar, {
        title: GetLangTemplate('HeaderNavBar')
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, null, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: PostData.value.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => PostData.value.username = $event),
            label: GetLangTemplate('vanField.username'),
            clearable: ""
          }, null, 8, ["modelValue", "label"]), _createVNode(_component_van_field, {
            modelValue: PostData.value.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => PostData.value.password = $event),
            label: GetLangTemplate('vanField.password'),
            clearable: ""
          }, null, 8, ["modelValue", "label"]), _createVNode(_component_van_field, {
            modelValue: PostData.value.name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => PostData.value.name = $event),
            label: GetLangTemplate('vanField.name'),
            clearable: ""
          }, null, 8, ["modelValue", "label"]), _createVNode(_component_van_field, {
            modelValue: PostData.value.contact,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => PostData.value.contact = $event),
            label: GetLangTemplate('vanField.contact'),
            clearable: ""
          }, null, 8, ["modelValue", "label"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanButton.submit')), 1)]),
          _: 1
        })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "warning",
          onClick: _cache[4] || (_cache[4] = () => _unref(Common_Router).RefreshPage())
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('vanButton.RefreshPage')), 1)]),
          _: 1
        })])]),
        _: 1
      })])]);
    };
  }
};